import React from 'react'
import Button from "../button/Button";
import {useTelegram} from "../../hooks/userTelegram";
import './Header.css'

const Header = () => {
    const {user, onClose} = useTelegram();

    return (
        <div className={'header'}>
            <Button onClick={onClose}>Close</Button>
            <span className={'username'}>
                {user?.username}
            </span>
        </div>
    )
}

export default Header;